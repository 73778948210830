import React, {useEffect, useState} from "react";

import {Modal} from "react-responsive-modal";
import Switch from "react-switch";
import {FaChevronRight, FaChevronDown} from "react-icons/fa";
import {CSSTransition} from "react-transition-group";
import Cookies from "js-cookie";
import {useCookiesContext} from "../context/CookiesCtx";

import "react-responsive-modal/styles.css";
import "../styles/modal-style.css";
import CommonStore from "../stores/common";

const CookiesModal = () => {
  const {addCookies} = useCookiesContext();

  const [open, setOpen] = useState(true);
  const [openAdvancedOptions, setOpenAdvancedOptions] = useState(false);

  const [analyticsSelected, setAnalyticsSelected] = useState(false);
  const [preferenceSelected, setFunctionalSelected] = useState(false);

  const [infoNecesaryOpen, setInfoNecesaryOpen] = useState(false);
  const [infoAnalyticsOpen, setInfoAnalyticsOpen] = useState(false);
  const [infoPreferenceOpen, setInfoPreferenceOpen] = useState(false);

  const [isConfigurationChanged, setConfigurationChanged] = useState(false);

  const setShowPolicy = CommonStore((state) => state.setShowPolicy);
  const setShowPointsList = CommonStore((state) => state.setShowPointsList);
  const generalTexts = CommonStore((state) => state.generalTexts);

  const language = CommonStore((state) => state.currentLang);

  const saveAcceptedCookies = () => {
    Cookies.set("cookie_consent", "true", {path: "/", expires: 365});
    Cookies.set("cookie_survey", "false", {path: "/", expires: 365});
    if (analyticsSelected)
      Cookies.set("an_cookie_consent", "true", {path: "/", expires: 365});
    if (preferenceSelected) {
      Cookies.set("cookie_language", localStorage.getItem("lang")!, {
        path: "/",
        expires: 365,
      });

      Cookies.set("pf_cookie_consent", "true", {path: "/", expires: 365});
    }
    addCookies(true, analyticsSelected, preferenceSelected);
  };

  const saveAll = () => {
    Cookies.set("cookie_consent", "true", {path: "/", expires: 365});
    Cookies.set("an_cookie_consent", "true", {path: "/", expires: 365});
    Cookies.set("pf_cookie_consent", "true", {path: "/", expires: 365});
    Cookies.set("cookie_language", localStorage.getItem("lang")!, {
      path: "/",
      expires: 365,
    });
    Cookies.set("cookie_survey", "false", {path: "/", expires: 365});

    addCookies(true, true, true);
  };

  useEffect(() => {
    console.log(language);
  }, [language]);

  return (
    <Modal
      open={open}
      blockScroll={false}
      onClose={() => {}}
      closeOnOverlayClick={false}
      closeOnEsc={false}
      showCloseIcon={false}
      center
      classNames={{
        overlay: "cookiesModalOverlay",
        modal: "cookiesModal",
      }}
      focusTrapped={false}
    >
      <div className="px-6 py-4 sm:px-8 sm:py-6 bg-rectangle-blue text-white flex items-center justify-center">
        <div className="container">
          {" "}
          <div>
            <h2 className="text-base mb-2">
              {generalTexts?.information_about_cookies}
            </h2>
            <p className="text-xs">
              {generalTexts?.description_cookies}{" "}
              <div
                onClick={() => {
                  setShowPointsList(false);
                  setShowPolicy(2);
                }}
              >
                <u>{generalTexts?.cookies_policy}</u>
              </div>
            </p>
          </div>
          {openAdvancedOptions && (
            <div className="flex flex-col my-4">
              <div
                className="flex items-center justify-between p-2 my-1 rounded-lg"
                style={{backgroundColor: "#c70091"}}
              >
                <div className="flex items-center justify-center">
                  {!infoNecesaryOpen ? (
                    <span>
                      <FaChevronRight
                        className="mr-2 cursor-pointer"
                        onClick={() => {
                          if (infoAnalyticsOpen) setInfoAnalyticsOpen(false);
                          if (infoPreferenceOpen) setInfoPreferenceOpen(false);
                          setInfoNecesaryOpen(true);
                        }}
                      />{" "}
                    </span>
                  ) : (
                    <span>
                      <FaChevronDown
                        className="mr-2 cursor-pointer"
                        onClick={() => setInfoNecesaryOpen(false)}
                      />{" "}
                    </span>
                  )}

                  <span className="text-sm">
                    {generalTexts?.required_cookies}
                  </span>
                </div>
                <div className="flex items-center">
                  {" "}
                  <span className="text-sm">
                    {generalTexts?.always_enabled_cookies}
                  </span>
                </div>
              </div>
              <CSSTransition
                in={infoNecesaryOpen}
                timeout={300}
                classNames="necesary-info-text"
                unmountOnExit
              >
                <div className="p-2 pl-10">
                  <p className="text-xs">
                    {generalTexts?.description_required_cookies}
                  </p>
                  <table className="w-full my-4 table-auto text-xs">
                    <thead>
                      <tr className="bg-gray-400 text-black">
                        <th className="p-2">Cookie</th>
                        <th className="p-2">{generalTexts?.duration}</th>
                        <th className="p-2">{generalTexts?.description}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="bg-gray-100 text-black">
                        <td className="p-2">cookie_survey</td>
                        <td className="p-2">
                          {language.code === "ru-RU"
                            ? `1 год`
                            : `1 ${generalTexts?.year}`}
                          {/* {`1 ${generalTexts?.year}`} */}
                        </td>
                        <td className="p-2">
                          {generalTexts?.cookie_survey_description}
                        </td>
                      </tr>
                      {/* <tr className="bg-gray-200 text-black">
                        <td className="p-2">cookie_survey</td>
                        <td className="p-2">1{` ${generalTexts?.year}`}</td>
                        <td className="p-2">
                          {generalTexts?.cookie_survey_description}
                        </td>
                      </tr> */}
                    </tbody>
                  </table>
                </div>
              </CSSTransition>
              <div
                className="flex items-center justify-between p-2 my-1 rounded-lg"
                style={{backgroundColor: "#c70091"}}
              >
                <div className="flex items-center">
                  {!infoAnalyticsOpen ? (
                    <span>
                      <FaChevronRight
                        className="mr-2 cursor-pointer"
                        onClick={() => {
                          if (infoNecesaryOpen) setInfoNecesaryOpen(false);
                          if (infoPreferenceOpen) setInfoPreferenceOpen(false);
                          setInfoAnalyticsOpen(true);
                        }}
                      />{" "}
                    </span>
                  ) : (
                    <span>
                      <FaChevronDown
                        className="mr-2 cursor-pointer"
                        onClick={() => setInfoAnalyticsOpen(false)}
                      />{" "}
                    </span>
                  )}
                  <span className="text-sm">
                    {generalTexts?.analytical_cookies}
                  </span>
                </div>
                <div className="flex items-center">
                  {analyticsSelected ? (
                    <span className="mr-2 text-sm">
                      {generalTexts?.enabled_cookies}
                    </span>
                  ) : (
                    <span className="mr-2 text-sm">
                      {generalTexts?.disabled_cookies}
                    </span>
                  )}

                  <Switch
                    onChange={() => {
                      setAnalyticsSelected(!analyticsSelected);
                      if (!isConfigurationChanged)
                        setConfigurationChanged(true);
                    }}
                    checked={analyticsSelected}
                    height={24}
                  />
                </div>
              </div>
              <CSSTransition
                in={infoAnalyticsOpen}
                timeout={300}
                classNames="necesary-info-text"
                unmountOnExit
              >
                <div className="p-2 pl-10">
                  <p className="my-1 text-xs">
                    {generalTexts?.description_analytical_cookies}
                  </p>

                  <table className="w-full my-4 table-auto text-xs">
                    <thead>
                      <tr className="bg-gray-400 text-black">
                        <th className="p-2">Cookie</th>
                        <th className="p-2">{generalTexts?.duration}</th>
                        <th className="p-2">{generalTexts?.description}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="bg-gray-100 text-black">
                        <td className="p-2">_pk_id</td>
                        <td className="p-2">13{` ${generalTexts?.month}`}</td>
                        <td className="p-2">
                          {generalTexts?.pkid_description}
                        </td>
                      </tr>
                      <tr className="bg-gray-200 text-black">
                        <td className="p-2">_pk_ses</td>
                        <td className="p-2">30{` ${generalTexts?.minute}`}</td>
                        <td className="p-2">
                          {generalTexts?.pkses_description}
                        </td>
                      </tr>
                      <tr className="bg-gray-100 text-black">
                        <td className="p-2">matomo_sessid</td>
                        <td className="p-2"></td>
                        <td className="p-2">
                          {generalTexts?.sesid_description}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </CSSTransition>
              <div
                className="flex items-center justify-between p-2 my-1 rounded-lg"
                style={{backgroundColor: "#c70091"}}
              >
                <div className="flex items-center">
                  {!infoPreferenceOpen ? (
                    <span>
                      <FaChevronRight
                        className="mr-2 cursor-pointer"
                        onClick={() => {
                          if (infoNecesaryOpen) setInfoNecesaryOpen(false);
                          if (infoAnalyticsOpen) setInfoAnalyticsOpen(false);
                          setInfoPreferenceOpen(true);
                        }}
                      />{" "}
                    </span>
                  ) : (
                    <span>
                      <FaChevronDown
                        className="mr-2 cursor-pointer"
                        onClick={() => setInfoPreferenceOpen(false)}
                      />{" "}
                    </span>
                  )}
                  <span className="text-sm">
                    {generalTexts?.preference_cookies}
                  </span>
                </div>
                <div className="flex items-center">
                  {preferenceSelected ? (
                    <span className="mr-2 text-sm">
                      {generalTexts?.enabled_cookies}
                    </span>
                  ) : (
                    <span className="mr-2 text-sm">
                      {generalTexts?.disabled_cookies}
                    </span>
                  )}

                  <Switch
                    onChange={() => {
                      setFunctionalSelected(!preferenceSelected);
                      if (!isConfigurationChanged)
                        setConfigurationChanged(true);
                    }}
                    checked={preferenceSelected}
                    height={24}
                  />
                </div>
              </div>

              <CSSTransition
                in={infoPreferenceOpen}
                timeout={300}
                classNames="necesary-info-text"
                unmountOnExit
              >
                <div className="p-2 pl-10">
                  <p className="my-1 text-xs">
                    {generalTexts?.description_preference_cookies}
                  </p>

                  <table className="w-full my-4 table-auto text-xs">
                    <thead>
                      <tr className="bg-gray-400 text-black">
                        <th className="p-2">Cookie</th>
                        <th className="p-2">{generalTexts?.duration}</th>
                        <th className="p-2">{generalTexts?.description}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="bg-gray-100 text-black">
                        <td className="p-2">cookie_language</td>
                        <td className="p-2">
                          {language.code === "ru-RU"
                            ? `1 год`
                            : `1 ${generalTexts?.year}`}
                        </td>
                        <td className="p-2">
                          {generalTexts?.cookie_language_description}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </CSSTransition>
            </div>
          )}
          <div className="mt-4 sm:mt-6 flex flex-col sm:flex-row sm:items-center justify-between lg:mt-8">
            {!openAdvancedOptions && (
              <div>
                <button
                  onClick={() => setOpenAdvancedOptions(true)}
                  className="mb-2 sm:mb-0 mr-5 font-light text-sm underline text-white"
                >
                  {generalTexts?.advanced_configuration_cookies}
                </button>
                <button
                  //className="font-avenirBlack flex justify-center items-center rounded-full py-2 px-4 uppercase tracking-wide text-lg"
                  onClick={saveAll}
                  className="text-white rounded px-4 py-1"
                  style={{backgroundColor: "white", color: "#0900b9"}}
                >
                  {generalTexts?.accept_and_close_cookies}
                </button>
              </div>
            )}

            {openAdvancedOptions && (
              <div>
                <button
                  onClick={saveAcceptedCookies}
                  className="mb-2 sm:mb-0 rounded px-4 py-1 font-light"
                >
                  {generalTexts?.save_configuration_cookies}
                </button>
                <button
                  onClick={saveAll}
                  className="rounded px-4 py-1"
                  style={{backgroundColor: "white", color: "#0900b9"}}
                >
                  {generalTexts?.accept_and_close_cookies}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CookiesModal;
