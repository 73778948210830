/**
 * Google Analytics
 */
const GOOGLE_ANALYTICS_TRACKING_ID: string = "UA-220410856-1";
const GOOGLE_ANALYTICS_RAW_PATH: string = "/";
enum GOOGLE_ANALYTICS_CATEGORY {
  LANGUAGE = "Language",
  AREA = "Area",
  MULTIMEDIASECONDS = "Todas las pistas",
  MULTIMEDIAPERCENTAGE = "% Todas las pistas",
  MAP = "Map",
  NOTIFICATION = "Notification",
  SURVEY = "Survey",
  READMORE = "Total",
}

// const DIRECTUS_BASE_URL: string =
//   "https://audioguiacms.miradortorreglories.com";

//const DIRECTUS_BASE_URL: string = "http://localhost:8073";

const DIRECTUS_BASE_URL: string = "https://dev-audioguiacms.imascono.com";

/**
 * Category Colors
 */
enum CATEGORY_COLOR {
  NONE = "#808080",
}

/**
 * Category IDs
 */
enum CATEGORY_ID {
  NONE = 0,
}

/**
 * Console TAGs
 */
enum CONSOLE_TAG {
  DIRECTUS = "Directus",
  FRAME = "Frame",
  SCREEN = "Screen",
  FLOATING = "Floating",
}

/**
 * Console TAG Colors
 */
enum CONSOLE_TAG_COLOR {
  DIRECTUS = "darkblue",
  FRAME = "darkgreen",
  SCREEN = "darkmagenta",
  FLOATING = "darkgoldenrod",
}

/**
 * Console TAG Visible
 */
enum CONSOLE_TAG_VISIBLE {
  DIRECTUS = 1,
  FRAME = 1,
  SCREEN = 1,
  FLOATING = 1,
}

/**
 * Colors
 */
const LEKUE_COLORS = {
  LIGHT_GRAY: "#c5c5c5",
};

/**
 * Virtual Home Frame IDs
 */
enum VIRTUAL_HOME_FRAME_ID {
  ENTRANCE = 1,
  WALL_TV = 2,
  SOFA = 3,
}

const ProjectConfiguration = {
  /**
   * Google Analytics
   */
  REACT_GA_TRACKING_ID: GOOGLE_ANALYTICS_TRACKING_ID,
  REACT_GA_RAW_PATH: GOOGLE_ANALYTICS_RAW_PATH,
  GOOGLE_ANALYTICS_CATEGORY: GOOGLE_ANALYTICS_CATEGORY,

  /**
   * Directus
   */
  DIRECTUS_BASE_URL: DIRECTUS_BASE_URL,

  /**
   * Category Colors
   */
  CATEGORY_COLOR: CATEGORY_COLOR,

  /**
   * Category IDs
   */
  CATEGORY_ID: CATEGORY_ID,

  /**
   * Console TAGs
   */
  CONSOLE_TAG: CONSOLE_TAG,

  /**
   * Console TAG Colors
   */
  CONSOLE_TAG_COLOR: CONSOLE_TAG_COLOR,

  /**
   * Console TAG Visible
   */
  CONSOLE_TAG_VISIBLE: CONSOLE_TAG_VISIBLE,

  /**
  
   */
  LEKUE_COLORS: LEKUE_COLORS,

  /**
   * Virtual Home Frame IDs
   */
  VIRTUAL_HOME_FRAME_ID: VIRTUAL_HOME_FRAME_ID,
};

export default ProjectConfiguration;
